import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AlgoToaster from './components/AppToaster';
import { Suspense } from 'react';
import { lazy } from 'react';
import { UserProvider } from './context/userContext';
import { ArtisanList } from './pages/ArtisanList';
import { PageSpinner } from './components/PageSpinner';

const AdminFinanceNav = lazy(() => import('./layouts/admin/AdminFinanceNav'));
const AdminOrdersNav = lazy(() => import('./layouts/admin/AdminOrdersNav'));
const AdminAllUsersNav = lazy(() => import('./layouts/admin/AdminAllUsersNav'));
const UserProfileNav = lazy(() => import('./layouts/users/UserProfileNav'));
const UserSecurityNav = lazy(() => import('./layouts/users/UserSecurityNav'));
const UserNewOrderNav = lazy(() => import('./layouts/users/UserNewOrderNav'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassowrd'));
const UserOrderNav = lazy(() => import('./layouts/users/UserOrderNav'));
const UserQuoteNav = lazy(() => import('./layouts/users/UserQuoteNav'));
const Home = lazy(() => import('./pages/Home'));
const UnAuthorized = lazy(() => import('./pages/UnAuthorized'));
const Faq = lazy(() => import('./pages/Help'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Login = lazy(() => import('./pages/Login'));
const Signup = lazy(() => import('./pages/Signup'));
const ArtisanRegister = lazy(() => import('./pages/ArtisanRegister'));
const About = lazy(() => import('./pages/About'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));
const OtpVerification = lazy(() => import('./pages/OtpVerification'));
const AdminArtisanNav = lazy(() => import('./layouts/admin/AdminArtisanNav'));
const RequestOtp = lazy(() => import('./pages/RequestOtp'));
const ArtisanSecurityNav = lazy(() => import('./layouts/artisans/ArtisanSecurityNav'));
const Logout = lazy(() => import('./pages/Logout'));
const ArtisanOrderNav = lazy(() => import('./layouts/artisans/ArtisanOrderNav'));
const ArtisanQuoteNav = lazy(() => import('./layouts/artisans/ArtisanQuoteNav'));
const ArtisanProfileNav = lazy(() => import('./layouts/artisans/ArtisanProfileNav'));
const AdminDashboardNav = lazy(() => import('./layouts/admin/AdminDashboardNav'));
const ServiceCategoriesNav = lazy(() => import('./layouts/admin/ServiceCategoriesNav'));
const AdminQuoteNav = lazy(() => import('./layouts/admin/AdminQuoteNav'));

// User
const UserDashboardNav = lazy(() => import('./layouts/users/UserDashboardNav'));

const App = () => {
  return (
    <UserProvider>
      <AlgoToaster />
      <BrowserRouter>
        <Suspense fallback={<PageSpinner grow={false} />}>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/help" element={<Faq />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/privacy-policy" element={<Privacy />}></Route>
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/artisans/:category" element={<ArtisanList />}></Route>
            {/* user  */}
            <Route path="/user/profile" element={<UserProfileNav />}></Route>
            <Route path="/user/security" element={<UserSecurityNav />}></Route>
            {/* <Route path="/user/wallet" element={<UserWalletNav />}></Route> */}
            <Route path="/user/wallet" element={<UserDashboardNav />}></Route>
            <Route path="/user/orders" element={<UserOrderNav />}></Route>
            <Route path="/user/quotes" element={<UserQuoteNav />}></Route>
            <Route path="/user/order/create" element={<UserNewOrderNav />}></Route>

            {/* Artisan  */}
            <Route path="/artisan/profile" element={<ArtisanProfileNav />}></Route>
            <Route path="/artisan/security" element={<ArtisanSecurityNav />}></Route>
            <Route path="/artisan/orders" element={<ArtisanOrderNav />}></Route>
            <Route path="/artisan/quotes" element={<ArtisanQuoteNav />}></Route>
            {/* <Route path="/artisan/wallet" element={<UserWalletNav />}></Route> */}

            {/* Admin  */}
            <Route path="/admin/dashboard" element={<AdminDashboardNav />}></Route>
            <Route path="/admin/users" element={<AdminAllUsersNav />}></Route>
            <Route path="/admin/artisans" element={<AdminArtisanNav />}></Route>
            <Route path="/admin/orders" element={<AdminOrdersNav />}></Route>
            <Route path="/admin/quotes" element={<AdminQuoteNav />}></Route>
            <Route path="/admin/finance" element={<AdminFinanceNav />}></Route>
            <Route path="/service/categories" element={<ServiceCategoriesNav />}></Route>

            {/* Auth  */}
            <Route path="/login" element={<Login />}></Route>
            <Route path="/logout" element={<Logout />}></Route>
            <Route path="/signup" element={<Signup />}></Route>
            <Route path="/register" element={<ArtisanRegister />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route path="/passwordreset/:email" element={<ResetPassword />}></Route>
            <Route path="/unauthorized" element={<UnAuthorized />}></Route>
            <Route path="/request-otp" element={<RequestOtp />}></Route>
            {/* <Route path="/verify-email/:email" element={<EmailVerification />}></Route> */}
            <Route path="/verify-email/:email" element={<OtpVerification />}></Route>

            {/* Not Found  */}
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserProvider>
  );
};
export default App;
