import { User } from '../data/models/User';
import { createContext, useContext, useState } from 'react';

type UserType = {
  loggedInUser: User | null;
  setLoggedInUser: React.Dispatch<React.SetStateAction<User | null>>;
};
const UserContext = createContext<UserType>({} as UserType);
interface UserContextProps {
  children: any;
}

export const UserProvider = ({ children }: UserContextProps) => {
  const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

  return <UserContext.Provider value={{ loggedInUser, setLoggedInUser }}>{children}</UserContext.Provider>;
};

export default UserContext;
export const useUserContext = () => useContext(UserContext);
