import { HttpMethod } from '../data/models/HttpVerbs';
import { keys } from '../utils/Keys';
import { Store } from '../utils/Store';

async function api(input: RequestInfo, http: HttpMethod, data?: any, useToken: boolean = false) {
  let init: RequestInit = getHttpHeader(http, data, useToken);
  const response = await fetch(input, init);
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    if (response.status === 401) {
      localStorage.clear();
      window.location.href = '/unauthorized';
    }
    const contentType = response.headers.get('Content-Type');

    if (contentType && contentType.includes('json')) {
      const json = await response.json();
      if (json.message) throw Error(json.message);
      throw Error(json);
    } else {
      const text = await response.text();
      throw Error(text);
    }
  }
}
function getHttpHeader(http: HttpMethod, data: any, useToken: boolean = false) {
  let init: RequestInit = { method: 'GET' };
  const token = Store.getItem<string>(keys.ACCESS_TOKEN);

  const headers = useToken
    ? http === HttpMethod.FILE || http === HttpMethod.FILE_PUT
      ? new Headers({
          Authorization: `Bearer ${token}`,
        })
      : new Headers({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        })
    : new Headers({
        'Content-Type': 'application/json',
      });

  switch (http) {
    case HttpMethod.POST:
      init = {
        method: 'POST',
        headers: headers,
        // credentials: 'include', //comment this out for .NET backend but not for node
        body: JSON.stringify(data),
      };
      break;
    case HttpMethod.FILE:
      init = {
        method: 'POST',
        headers: headers,
        body: data,
      };
      break;
    case HttpMethod.FILE_PUT:
      init = {
        method: 'PUT',
        headers: headers,
        body: data,
      };
      break;
    case HttpMethod.PATCH:
      init = {
        method: 'PATCH',
        headers: headers,
        // credentials: 'include',
        body: JSON.stringify(data),
      };
      break;
    case HttpMethod.PUT:
      init = {
        method: 'PUT',
        headers: headers,
        // credentials: 'include',
        body: JSON.stringify(data),
      };
      break;
    case HttpMethod.DELETE:
      init = { method: 'DELETE', headers: headers, body: JSON.stringify(data) };
      break;
    case HttpMethod.GET:
      init = { method: 'GET', headers: headers };
      break;
    default:
      init = { method: 'GET', headers: headers };
      break;
  }
  return init;
}
export default api;
