const keys = {
  CURRENT_USER: 'cu',
  ACCESS_TOKEN: 'uuid',
  ARTISAN_ID: 'auid',
  DELETE_ORDER_ID: 'doid',
  REVIEW_ORDER_ID: 'roid',
  REJECT_QUOTE_ID: 'rqid',
  PAYMENT_QUOTE_ID: 'pqid',
  DELETE_CATEGORY_ID: 'dcid',
  ACCEPT_OR_REJECT_ORDER_ID: 'arid',
  ADMIN_APPROVE_OR_REJECT_ORDER_ID: 'adrid',
  ADMIN_APPROVE_OR_REJECT_QUOTE_ID: 'adqid',
  SUSPEND_OR_ACTIVATE_ARTISAN_ID: 'suid',
  QUOTE_ORDER_ID: 'qoid',
  INSPECTION_FEE: 'ife',
  OTP_CODE: 'otc',
};

export { keys };
