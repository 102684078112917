import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../src/index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('app') as HTMLElement);

root.render(
  // <React.StrictMode>
  //   <App socket={socket} />
  // </React.StrictMode>
  <App />
);
