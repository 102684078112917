import React, { memo } from 'react';
import ArtisanRating from './ArtisanRating';
interface ServiceItemProps {
  name: string;
  imageURL: string;
  reviewCount: number;
  rating: number;
  profession: string;
}
const ArtisanGallary: React.FC<ServiceItemProps> = ({ name, imageURL, reviewCount, rating, profession }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-4">
      <div className="card rounded-0 border-0 shadow-sm eq-height">
        <div className="card-img-container position-relative">
          <img height={200} className="card-img-top rounded-0" src={imageURL ? imageURL : '/assets/img/avatars/user-placeholder.svg'} alt={name} />
          <div className="card-img-overlay overlay-mask text-center p-0">
            <div className="overlay-mask-content text-center w-100 position-absolute"></div>
          </div>
        </div>
        <div className="card-body pb-4">
          <h4 className="card-title mb-2">{name}</h4>
          <div className="card-text">
            <div className="excerpt">{profession}</div>
          </div>
        </div>
        <div className="card-footer border-0 d-flex justify-content-between">
          <ArtisanRating rating={rating} ratingCount={reviewCount} />
        </div>
      </div>
    </div>
  );
};

export default memo(ArtisanGallary);
